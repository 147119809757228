<script>
/* eslint-disable */
import DefaultMixin from '../mixin'

export default {
  name: 'LeilaoFechamento',
  inject: ['page'],
  mixins: [DefaultMixin],
  components: {},
  data() {
    return {
      leilao: this.page.leilao
    }
  },
  computed: {
    listType () {
      return this.$route.name
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    changeListType(v) {
      this.$router.push({name: v})
    }
  },
  meta: {
    title: 'Fechamento do Leilão',
    name: 'Fechamento'
  }
}
</script>

<template>
  <div class="page-leilao-container leilao-fechamento">
    <div class="h-full">
      <div class="page-tab">
        <div class="erp-list list-tasks no-pad">
          <div class="erp-list-top-btns items-end">
            <div class="actives-btns col-grow-1" style="margin-left: 60px">
              <u-btn @click="changeListType('leiloes.fechamento.formalizacao')" class="active-table-btn" label="Formalização" no-caps
                     :class="{active: listType === 'leiloes.fechamento.formalizacao'}"/>
              <u-btn @click="changeListType('leiloes.fechamento.cobranca')" class="active-table-btn" label="Cobrança" no-caps
                     :class="{active: listType === 'leiloes.fechamento.cobranca'}"/>
              <u-btn @click="changeListType('leiloes.fechamento.notas')" class="active-table-btn" label="Notas" no-caps
                     :class="{active: listType === 'leiloes.fechamento.notas'}"/>
            </div>
          </div>
        </div>
        <div class="page-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
